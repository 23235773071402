/******************************************************************************************
<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet">
GENERAL STYLES

*******************************************************************************************/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

#requiredFunctionality {
    font-size: 20px;

    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;

    width: 100%;
    padding: 20px 10px;

    transition: 0.3s all ease;
    text-align: center;

    color: #cccccc;
    background: rgba(0, 0, 0, 0.7);
    background-color: #000000;
}

#requiredFunctionality:hover {
    color: #ffffff;
    background: #000000;
}

#requiredFunctionality a {
    display: inline-block;

    margin: 0 0 0 5px;

    color: #cccccc;
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;

    position: inherit;
    left: 0;

    overflow-x: hidden;

    width: 100%;

    transition: 0.5s;

    color: #333333;

    -webkit-font-smoothing: antialiased;
    @media (min-width: 992px) {
        padding-top: 120px;
    }
}

body.active {
    overflow-y: hidden;
}

body .menu-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    visibility: hidden;

    cursor: url("../images/layout/x.png"), auto;
    transition: 0.5s ease;

    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
}

body.active .menu-overlay {
    visibility: visible;

    opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.mainNav li,
button,
form .form-control {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

a {
    transition: all ease 0.3s;

    color: #333333;
}

a:focus,
a:hover,
a:active {
    color: #f2c3ca;
}

.copy a,
.panel-body a {
    color: #56313b;
}
.copy a:hover,
.panel-body a:hover {
    text-decoration: underline !important;
}

.copy a:focus,
.copy a:hover,
.copy a:active {
    text-decoration: none;
}

ul,
ul li {
    margin: 0;
    padding: 0;

    list-style: none;
}

iframe {
    border: 0;
    max-width:100%;
}

.img-responsive {
    width: 100%;
}

.copy img {
    max-width: 100%;
    height: auto;
}

.copy > p:first-child {
    padding: 30px;
    margin-bottom: 20px;

    // background-color: #ffebee;
}
.content.col-sm-12.col-md-8 .copy > p {
    margin: 0;
    padding: 15px 30px;
    margin-bottom: 20px;

    // background-color: #ffebee;
}
.copy p:empty {
    display: none;
}

.copy iframe {
    margin-bottom: 15px;
}
/*COPY
 General headings, paragraphs etc
 ********************************************************/

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited,
h5 a:visited,
h6 a:visited,
h1 a:visited,
h2 a:active,
h3 a:active,
h4 a:active,
h5 a:active,
h6 a:active,
h1 a:visited,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h5 a:focus,
h6 a:focus {
    text-decoration: none;

    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
}

p,
.copy ul,
.copy ol {
    margin: 10px 0;
}

h2 {
    font-size: 30px;

    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
        font-size: 40px;

        margin-bottom: 15px;
    }
}

h3 {
    font-size: 24px;
    line-height: 28px;
}

.copy h2 {
    font-size: 26px;

    color: #333333;
}
.copy h3 {
    font-size: 24px;

    color: #333333;
}

.copy ul,
.copy ol {
    margin: 20px 0 20px;
}

.copy blockquote p {
    margin: 0 0 10px;
}

.copy blockquote p:first-child {
    margin-top: 0;
}

.copy ol {
    margin-left: 10px;
}

.copy li {
    position: relative;
}

.copy ul li,
.panel-body li {
    position: relative;

    padding-left: 20px;
}
.panel-body li {
    margin-bottom: 3px;
}
.copy ul li:before,
.panel-body li:before {
    font-size: 8px;

    position: absolute;
    top: 11px;
    left: 0;

    display: block;

    width: 6px;
    height: 6px;

    content: "";

    border-radius: 50%;
    background-color: #31275b;
}

/*BREADCRUMB PATH / OTHER PAGE TOP CONTENT
 *******************************************************/

.breadcrumb {
    position: relative;

    margin: 0;
    padding: 0;

    border-radius: 0;
    background: transparent;
}

.breadcrumb a {
    font-size: 14px;

    color: #56313b;
}
.breadcrumb li:not(.sel) {
    a {
        text-decoration: underline;
    }
}
.breadcrumb > li + li:before {
    font-size: 14px;

    padding-right: 10px;
    padding-left: 10px;

    content: "/";

    opacity: 1;
    color: #56313b;
}
.breadcrumb a:hover {
    text-decoration: none;

    color: #f2c3ca;
}

/*SLICK CONTROLS
 *******************************************************/

.slick-slide {
    outline: none;
}

/*slick buttons*/

.slick-prev,
.slick-next {
    width: 55px;
    height: 95px;
}
.slick-prev {
    z-index: 11;
    left: 25px;
}
.slick-next {
    right: 25px;
}
.slick-prev:before,
.slick-next:before {
    font-family: "FontAwesome";
    font-size: 100px;

    color: #cbcbcc;
}
.slick-prev:hover,
.slick-next:hover {
    opacity: 1;
}
.slick-next:before {
    content: "\f105";
}
.slick-prev:before {
    content: "\f104";
}

.homeBanner .slick-prev,
.homeBanner .slick-next {
    top: 37%;
}

.testimonial-slider .slick-prev,
.testimonial-slider .slick-next {
    top: auto;
    bottom: 20px;

    width: 65px;
    height: 65px;

    background-color: #ffced5;
}
.testimonial-slider .slick-prev {
    z-index: 99;
    left: 95px;
}

.testimonial-slider .slick-next {
    right: auto;
    left: 175px;
}
.testimonial-slider .slick-prev:before,
.testimonial-slider .slick-next:before {
    font-family: "FontAwesome";
    font-size: 30px;

    width: 65px;
    height: 65px;

    color: #333333;
}

.separator {
    font-size: 24px;
    font-weight: 300;

    margin: 0 20px;
}

/*BUTTONS
 *****************************************************************/

.header .btn {
    font-size: 24px;

    padding: 15px 20px;
}
.btn,
.submitBut,
.blogList p.more a {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    position: relative;
    z-index: 2;

    overflow: hidden;

    padding: 15px;

    transition: 0.5s ease;
    white-space: normal;
    text-decoration: none !important;

    border-radius: 60px;
    background-color: #ffffff;
}
.btn:hover {
    color: #f2c3ca;
}

.dccom_gdNews .btn {
    line-height: 15px;

    padding: 5px 15px;

    text-transform: capitalize;

    color: #ffffff;
    border: 2px solid #f2c3ca;
    background-color: #f2c3ca;
}
.dccom_gdNews .btn:hover {
    color: #f2c3ca;
    background-color: transparent;
}

/*close buttons*/

.close,
button.close {
    font-size: 24px;

    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;

    overflow: hidden;

    margin: 0;
    padding: 15px;

    cursor: pointer;
    transition: 0.5s ease;
    text-align: center;

    opacity: 1;
    color: #ffffff;
    background: transparent;
    text-shadow: none;
}

.close:focus,
.close:hover {
    opacity: 0.8;
    color: #ffffff;
}

/*SLIDE OUT CONTENT
 *******************************************************/

.slideOutContent {
    position: fixed;
    z-index: 999;
    top: 0;
    right: -100%;
    bottom: 0;

    width: 100%;
    padding: 0;

    transition: 0.5s ease-in;

    color: #ffffff;
    background-color: #11263b;
}

.slideOutContent > .inner {
    position: relative;

    overflow-y: auto;

    height: 100%;
}

.slideOutContent > .inner:before {
    left: 100%;
}

.slideOutContent.active > .inner:before {
    left: 0;
}

.slideOutContent.active {
    right: 0;
}

.slideOutContent .image {
    padding: 0;

    text-align: center;

    background: #80ba27;
}

.slideOutContent .text {
    padding: 30px;
}

.slideOutContent .text * {
    color: #ffffff;
}

.slideOutContent h3 {
    font-size: 30px;
    line-height: 34px;
}

/*REMODAL CONTENT
 ******************************************************************/

.remodal {
    background: #ffced5;
}

.remodal-close {
    top: 10px;
    left: 10px;

    border-radius: 50%;
    background-color: #ffffff;
}

.remodal-wrapper form {
    text-align: left;
}

.remodal .form-group {
    overflow: hidden;

    margin-bottom: 10px;
}

.callBackForm .btn,
.blogList p.more a {
    font-weight: 600;
    line-height: 19px;

    padding: 8px 30px;

    color: #ffffff;
    border: 2px solid #f2c3ca;
    background-color: #f2c3ca;
}
.callBackForm .btn:hover,
.blogList p.more a:hover {
    color: #f2c3ca;
    background-color: transparent;
}

body div.formWizFormMessage {
    font-size: 14px !important;

    border-color: #f2c3ca !important;
    background-color: #f2f2f2 !important;
}

/*HEADER
 ******************************************************************/

.header.sticky {
    position: fixed;
    z-index: 100;
    left: 0;

    width: 100%;

    background: #ffffff;
}

.header .container-fluid {
    padding-top: 0;
    padding-bottom: 0;
}

.header .logo {
    display: block;
    float: left;

    max-width: 200px;
    margin-top: 10px;

    transition: 0.3s ease;
    @media all and (min-width: 768px) {
        max-width: 260px;
    }
}

body.active .header .logo {
    opacity: 0.2;
}

.header .contact-btn .btn,
.submitBut {
    font-weight: 300;

    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: #f2c3ca;
}
.btn.make-app {
    font-weight: 400 !important;
}
.header .contact-btn .btn span {
    font-weight: 700;
}

.header .btn-contact span {
    font-weight: 300;

    margin-left: 15px;
}
.header .contact-btn .btn:hover {
    color: #333333;
    border: 2px solid #f2c3ca;
    background: #ffffff;
}

@media (min-width: 992px) {
    .header {
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        left: 0;

        display: flex;
        align-items: center;

        height: 120px;

        border-bottom: 1px solid transparent;
        background-color: #ffffff;
    }
}

/*MAIN NAV
 ********************************************************************/

@media (max-width: 991px) {
    .showMenu {
        font-size: 24px;

        position: absolute;
        top: 15px;
        right: 14px;

        height: 40px;
        padding: 2px 10px 0;

        transition: 0.3s ease;

        color: #ffffff;
        border: 0;
        background: #35505a;
        border-radius: 8px;
    }

    /*SLIDE OUT MENU*/

    .slideOutMenu {
        font-weight: 400;

        position: fixed;
        z-index: 999;
        top: 0;
        right: -90%;
        bottom: 0;

        overflow-y: auto;
        width: 90%;
        max-width: 400px;
        padding-top: 40px;

        transition: 0.5s ease-in;

        background: #f1e5e7;
        // background-color: white;
    }

    .fa-times {
        color: #f2c3ca;
    }
    .slideOutMenu.active {
        right: 0;
    }

    /*main nav*/

    .mainNav {
        margin: 35px 15px 0;

        list-style: none;
    }

    .mainNav > li {
        position: relative;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .mainNav > li > a {
        font-size: 18px;

        display: inline-block;

        height: 100%;
        margin-right: 10px;
        padding: 7px 0;

        vertical-align: middle;
        text-transform: uppercase;

        color: #333333;
    }
    .mainNav > li > a:hover,
    .mainNav .sub-menu a:hover {
        text-decoration: none;

        color: #ffffff;
    }

    .mainNav .showSubNav {
        font-size: 20px;
        line-height: 20px;

        position: absolute;
        z-index: 10;
        top: 9px;
        right: 0;

        display: block;

        width: 22px;
        height: 22px;

        cursor: pointer;
        text-align: center;

        color: #ffffff;
    }
    .mainNav .showSubNav:hover {
        color: #333333;
    }
    .mainNav .showSubNav .fa:before {
        display: block;

        transition: 0.3s all;
    }

    .mainNav .open .showSubNav .fa:before {
        transform: rotate(180deg);
    }
    .mainNav .haschildren .subNavList1 {
        @media all and (min-width: 992px) {
            overflow: hidden;

            max-height: 0;
            margin: 0;
            padding: 0 0 0 30px;

            transition: all 0.5s;

            border: 0;
        }
    }
    .mainNav .haschildren.open .subNavList1 {
        max-height: 500px;
        padding-bottom: 10px;
    }
    .mainNav .sub-menu li {
        margin-bottom: 10px;
    }
    .sub-menu a {
        font-size: 14px !important;

        text-transform: uppercase;
    }
}

@media (min-width: 992px) {
    /*MAIN NAV*/
    .mainNav {
        display: block;

        width: 100%;

        transition: 0.5s ease;
    }

    .mainNav > li > a,
    .mainNav > li > a:hover,
    .mainNav > li > a:active {
        transition: 0.5s ease;
        text-decoration: none;
    }
    .mainNav > li:hover,
    .mainNav > li.onPath {
        background-color: #ffffff;
    }
    .mainNav .showSubNav i {
        font-size: 25px;

        position: absolute;
        top: 20px;

        color: #ffffff;
    }
    .showSubNav {
        vertical-align: middle;
    }
    .mainNav li.lev1:hover .showSubNav i,
    .mainNav li.lev1.onPath .showSubNav i {
        color: #ffced5;
    }

    .mainNav .sub-menu {
        position: absolute;
        z-index: 99;

        visibility: hidden;

        width: 350px;
        padding: 8px 0;
        padding: 20px;

        transition: all 0.3s ease 0s;
        transform: translate3d(0px, 10px, 0px);
        text-align: left;

        opacity: 0;
        border: 1px dashed #b3cfd9;
        background: white;
    }

    .mainNav > li.haschildren .sub-menu.open {
        visibility: visible;

        transform: translate3d(0px, 0px, 0px);

        opacity: 1;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.17) inset;
    }

    .mainNav > li.haschildren .sub-menu.open a {
        display: block;

        margin-bottom: 10px;
    }
}

@media all and (max-width: 991px) {
    .mainNav > li {
        display: block;

        width: auto;
        padding: 10px;
    }
}

/*PAGE NAV
 **********************************************************************/

.pageNavList {
    margin: 0;

    list-style: none;
}

.pageNavList li {
    position: relative;

    overflow: hidden;

    border-bottom: 1px solid #cccccc;
    background: transparent;
}

.pageNavList > li > a,
#news ul.pageNavList ul li a {
    font-size: 17px;

    position: relative;
    z-index: 10;

    display: inline-block;

    width: 100%;
    padding: 12px 20px;

    transition: 0.5s ease;
    text-decoration: none;

    color: #333333;
}

#news ul.pageNavList ul li a {
    padding-left: 30px;
}
#news ul.pageNavList ul ul li a {
    padding-left: 40px;
}

/*onPath sel*/

.pageNavList li.onPath {
    border-bottom: 1px solid #ffced5;
}
.pageNavList li.onPath.sel {
    border-bottom: 1px solid #cccccc;
    background-color: #f2f2f2;
}
.pageNavList li.onPath.sel a {
    font-weight: 600;

    color: #333333;
}
.pageNavList li.active,
.pageNavList li:hover {
    background: #f2f2f2;
}
.pageNavList li.active a,
.pageNavList li:hover a {
    color: #000000;
}
.pageNavList li.onPath a {
}

/*show sub nav*/

.pageNavList .showSubNav {
    font-size: 22px;
    line-height: 22px;

    position: absolute;
    z-index: 10;
    top: 0;
    right: 40px;

    display: block;

    width: 50px;
    height: 50px;
    padding-top: 12px;

    cursor: pointer;
    text-align: center;

    color: #ffffff;
}

.pageNavList .showSubNav .fa:before {
    display: block;

    transition: 0.3s all;
}

.pageNavList .open .showSubNav .fa:before {
    transform: rotate(180deg);
}

/*sub nav*/

.pageNavList .wrap {
    position: relative;
    z-index: 12;

    overflow: hidden;

    background: #ffffff;
}

.pageNavList .subNavList1,
.pageNavList .subNavList2 {
    max-height: 0;
    margin: 0;
    padding: 0 0 0 20px;

    transition: all 0.5s;

    border: 0;
}

.pageNavList .open .subNavList1
 /*.pageNavList .onPath .subNavList1,*/ {
    max-height: 1200px;
    padding-top: 30px;
    padding-bottom: 10px;
}

.pageNavList .subNavList1 a {
    font-size: 14px;
    font-weight: 400;

    display: block;

    margin-right: 50px;
    padding: 4px 10px 4px 20px;

    transition: 0.5s ease;

    color: #464646;
}

.pageNavList .subNavList1 a:hover,
.pageNavList .subNavList1 a:active,
.pageNavList .subNavList1 .onPath > a {
    text-decoration: none;

    color: #0068b3;
    background: #f3f3f3;
}

.pageNavList .wrap p {
    padding: 0 20px;
}

/*BANNER
 used for rotating images sliders / hero images
 the image height will dictate the height of the slider
 ***********************************************************************/
.banner .row {
    margin: 0;
    padding: 0;
}
.banner .container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    margin: 0 auto;

    transform: translateY(-50%);
}
.banner .text {
    z-index: 10;

    max-width: 580px;
    padding: 50px 55px;

    color: #ffffff;
    border-radius: 6px;
    background-color: rgba(#56313b, 0.9);
}
.section--inner h1,
.banner h1 {
    font-size: 24px;
    font-weight: 600;

    margin-bottom: 20px;
    @media screen and (min-width: 992px) {
        font-size: 32px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 38px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 44px;
    }
}
.banner p {
    font-size: 14px;
    line-height: 175%;

    margin-bottom: 36px;
    @media screen and (min-width: 992px) {
        font-size: 16px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
}
.banner .image-location .text {
    left: 27%;

    width: 55%;
}

.banner .inner {
    width: 100%;
}

.banner img {
    width: 100%;
}

.banner .image {
    position: relative;

    width: 100%;
}
.banner .image-location {
    float: left;

    width: 60%;
}
.banner .map-location {
    float: left;

    width: 40%;
    height: 670px;
}
.banner .image-location {
    position: relative;
}
.banner .image-location:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: "";

    background-color: rgba(84, 19, 29, 0.75);
}
.banner .image-location .btn {
    margin-top: 15px;
    padding: 13px 15px;

    text-transform: uppercase;
}
img.desaturate {
    -webkit-filter: grayscale(100%); /* New WebKit */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
    filter: grayscale(100%); /* Current draft standard */
    /* filter: url(resources.svg#desaturate); /* Gecko */
    filter: gray; /* IE */
}

article.news-item {
    margin-bottom: 25px;
}

/*** Homepage
 *********************/
body#home #wrap {
    position: relative;
    z-index: 2;

    // background-image: url("../images/layout/bg.jpg");
    // background-repeat: no-repeat;
    // background-position: 50% 50%;
    // background-size: cover;
}
/*body#home #wrap:before {
     content: '';
     position: absolute;
     top: 10px;
     background: url('../images/layout/bg.jpg')
 }*/

// #home .contentWrap {
//     margin-top: -10%;
// }
body#home .contentWrap h1 {
    font-size: 40px;
}
body#home .contentWrap .section--bleed-right h1,
body#home .contentWrap .section--bleed-right h2 {
    color: #56313b;
}

.section--bleed-left,
.section--bleed-right {
    .image {
        img {
            @media all and (max-width: 767px) {
                width: 100%;
            }
        }
    }
    .row {
        position: relative;
    }
    // .col-sm-6.text{
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //     transform: translateY(-50%);
    // }
}

.servicesWrap .col-sm-6 {
    padding: 0 10px;
}
.infoBlock {
    position: relative;

    margin: 150px 0 30px;
}
.infoBlock .text {
    padding-left: 35px;
}
.infoBlock .row {
    background-color: #ffced5;
}
.infoBlock .image {
    position: absolute;
    top: -110px;
    left: -95px;

    padding-right: 25px;
}
.infoBlock a {
    display: block;

    padding: 60px 0;
}
.infoBlock a:hover {
    text-decoration: none;

    color: #333333;
}
.infoBlock a:hover .btn {
    color: #f2c3ca;
}
.infoBlock a p {
    margin-bottom: 25px;
}
.infoBlock a p.btn {
    margin-top: 10px;
    padding-top: 12px;
    padding-right: 40px;
    padding-bottom: 12px;

    text-transform: uppercase;
}
.infoBlock a p.btn:after {
    font: normal normal normal 17px/1 FontAwesome;

    position: absolute;
    right: 15px;

    margin-top: 5px;

    content: "\f0a9";
}
.infoBlock .col-md-6.text {
    padding-left: 60px;
}

.testimonialBlock {
    position: relative;

    margin: 90px 0 30px;
}
.testimonial-slider {
    width: 85%;
    margin: 0 auto;
}
/*.testimonialBlock .image {
     margin-top: -100px;
     right: -96px;
 }*/
.testimonialBlock .text {
    position: relative;

    min-height: 270px;
    margin-top: 70px;
    padding: 100px 0 80px 80px;

    background: url("../images/layout/marker-testimonial.png") #f2f2f2;
    background-repeat: no-repeat;
    background-position: 20px 20px;
}

.image-testimonial {
    position: absolute;
    top: 0;
    right: 0;

    width: 40%;
    margin-top: -70px;
    margin-right: -120px;
}
.text-testimonial {
    width: 50%;
}
.testimonialBlock .text > .col-sm-6:first-child {
    padding-bottom: 44px;
}

.testimonialBlock .text p {
    font-size: 16px;

    margin-bottom: 25px;
}
.testimonial-slider .item {
    padding: 0 15px;
}

/*FOOTER
 ***********************************************************************/

.footer {
    font-size: 16px;
    line-height: 1.8;

    padding: 50px 0;

    color: #3d4f5b;
    background-color: #f2f2f2;
    margin-top: 2px;
}
.footer a {
    color: #3d4f5b;
}

.footer h4 {
    font-size: 18px;
    font-weight: 600;

    color: #56313b;
}
.footer .span5 {
    float: left;

    width: 20%;
    padding: 0 10px;
}

#footer-map {
    width: 100%;
    height: 230px;
}
.footer .contact span {
    display: block;
}
.footer .opening {
    margin-top: 20px;
}
.footer .opening h2 {
    margin-bottom: 10px;
}
.footer .opening p {
    line-height: 20px;

    margin-top: 0;
    margin-bottom: 5px;
}
.nav-footer li a:hover {
    text-decoration: underline;

    color: #35505a;
}

.span5.last {
    width: 24%;
}
.span5.add {
    width: 16%;
}

/*SOCIAL NAV
 ***********************************************************************/

.navtwitter a:before {
    content: "\f099";
}
.navfacebook a:before {
    content: "\f09a";
}
.navlinkedin a:before {
    content: "\f0e1";
}
.navyoutube a:before {
    content: "\f16a";
}
.navflickr a:before {
    content: "\f16e";
}
.navrss a:before {
    content: "\f09e";
}
.navpinterest a:before {
    content: "\f0d2";
}
.navgoogle a:before {
    content: "\f0d5";
}
.navvimeo a:before {
    content: "\f27d";
}
.navinstagram a:before {
    content: "\f16d";
}
.navtripadvisor a:before {
    content: "\f262";
}
.navplus a:before {
    content: "\f067";
}

.footer .socialNavList {
    margin: 30px 0;
}

.socialNavList li {
    display: inline-block;

    margin: 0 3px;
}

.socialNavList a {
    position: relative;

    display: block;

    width: 40px;
    height: 40px;
    padding: 0 10px;

    transition: 0.5s ease;
    text-align: center;
    text-indent: -5000px;

    color: #ffffff;
    border: 1px solid #f2c3ca;
    background: #f2c3ca;
}

.socialNavList a:before {
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 16px;

    position: absolute;
    top: 0;
    left: 13px;

    display: block;

    padding-top: 11px;

    text-align: center;
    text-indent: 0;
}
.socialNavList a:hover {
    background-color: #ffffff;
}
.socialNavList a:hover {
    color: #f2c3ca;
    /*box-shadow: 0 0 0 3px #ffcb05;*/
}

.socialNavList .navlinkedin a:before {
    left: 12px;
}

.socialNavList .navfacebook a:before {
    left: 14px;
}

/*************************************************************************************************
 
 CONTENT BLOCKS
 
 *************************************************************************************************/

/*INNER PAGE BLOCKS
 ***********************************************************/

@media all and (max-width: 991px) {
    .sidebar .inner {
        display: none;
    }

    .sidebar.open .inner {
        display: block;
    }
}

.showPageNav {
    font-size: 20px;

    position: relative;
    position: relative;
    z-index: 10;
    left: -15px;

    display: inline-block;
    overflow: hidden;

    width: calc(100% + 30px);
    margin-right: -20px;
    margin-bottom: 15px;
    padding: 10px 40px 10px 20px;

    transition: 0.5s ease;
    text-decoration: none;

    color: #56313b;
    background: #f2c3ca;
}

.showPageNav span {
    position: relative;
    z-index: 10;
}

.showPageNav .fa {
    font-size: 22px;
    line-height: 22px;

    position: absolute;
    z-index: 10;
    top: 15px;
    right: 25px;

    display: block;

    width: 22px;
    height: 22px;

    cursor: pointer;
    text-align: center;

    color: #56313b;
}

.showPageNav .fa:before {
    display: block;

    transition: 0.3s all;
}

.open .showPageNav .fa:before {
    transform: rotate(45deg);
}

/*innerPages
 ************************************/

.path {
    margin-bottom: 20px;

    background-color: #f2f2f2;
}
.intro {
    padding: 30px;

    background-color: #ffebee;
}
.dccom_twFAQ {
    margin-top: 10px;
}
.accordion {
    margin: 30px 0;
}
#your-pregnancy-guide {
    .panel.panel-default {
        .panel-heading {
            max-width: 380px;
        }
    }
    .panel-group:last-child {
        .panel.panel-default {
            .panel-heading {
                max-width: 580px;
            }
        }
    }
}
.panel.panel-default {
    margin-right: auto;
    margin-left: auto;

    border: none;
    box-shadow: none;
    .panel-heading {
        position: relative;
        z-index: 1;

        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    .panel-body {
        padding: 30px 40px;
        padding-top: 60px;
        @media screen and (min-width: 992px) {
            padding-top: 80px;
            padding: 40px 60px;
        }
        @media screen and (min-width: 1200px) {
            padding: 80px 100px;
        }
    }
}
.panel-group .panel {
    border-radius: 0;
}
.panel-default > .panel-heading {
    padding: 0;

    background-color: transparent;
}
.panel-title {
    font-size: 16px;
    font-weight: 600;

    @media (min-width: 992px) {
        font-size: 20px;
    }
}
.panel-heading .panel-title a {
    position: relative;

    display: block;

    padding-top: 29px;
    padding-right: 60px;
    padding-bottom: 29px;
    padding-left: 30px;

    border-radius: 6px;
    outline: none;
    background-color: #f2c3ca;
}

.panel-heading .panel-title a:after {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 40px;
    height: 40px;
    content: "";
    background-image: url("../images/layout/plus.png");
    transform: translateY(-50%);
    background-size: cover;
    @media (min-width: 992px) {
        right: 30px;
        width: 50px;
        height: 50px;
    }
}
.panel-heading .panel-title a[aria-expanded="true"] {
    background-color: rgba(179, 207, 217, 1);
}
.panel-heading .panel-title a[aria-expanded="true"]:after {
    font-size: 23px;

    background-image: url("../images/layout/minus.png");
}
.panel-default > .panel-heading + .panel-collapse {
    transform: translateY(-40px);

    border: 1px dashed rgba(179, 207, 217, 1) !important;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.panel-default > .panel-heading + .panel-collapse.collapsing {
    padding: 0;
}
.panel-group + .panel-group {
    position: relative;
    &::before {
        position: absolute;
        top: -60px;
        right: 0;
        left: 0;

        width: 1px;
        height: 60px;
        margin: 0 auto;

        content: "";

        border: 1px dashed rgba(179, 207, 217, 1);
    }
}

.copy .service-list li:before {
    background-color: transparent;
}

.service-list li a img {
    transition: box-shadow 0.3s ease;

    border: none;
}
.service-list li a p {
    padding: 0 15px;

    color: #333333;
    min-height: 60px;
}

/*STICKY Cookie Policy
 ***********************************************************/

div#stickycookiePolicy {
    position: fixed;
    z-index: 500;
    right: 0;
    bottom: 0;
    left: 0;

    height: auto;
    padding: 12px;

    color: #ffffff;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.8);
    background-color: #000000;
}

div#stickycookiePolicy:hover {
    background: rgba(0, 0, 0, 0.8);
    background-color: #000000;
}

div#stickycookiePolicy .inner {
    font-size: 14px;

    position: relative;
    z-index: 10;

    margin: 0 auto;

    text-align: center;
}

div#stickycookiePolicy a.more {
    color: #ffffff;
    color: #f2c3ca;
}
div#stickycookiePolicy a.more:hover {
    opacity: 1;
}

div#stickycookiePolicy a.close {
    position: relative;

    display: inline-block;

    padding: 3px 15px;

    cursor: pointer;
    text-indent: -5000px;
}

div#stickycookiePolicy a.close:hover {
    opacity: 1;
}

div#stickycookiePolicy a.close:before {
    font-family: "FontAwesome";
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    line-height: 32px;

    position: absolute;
    top: 0;
    right: 0;
    left: 3px;

    content: "\f057";
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-decoration: none;
    text-indent: 0;

    opacity: 0.5;
    color: #ffffff;
}

div#stickycookiePolicy a.close:hover:before {
    opacity: 1;
    color: #ffffff;
}

.dccom_gdtestimonials div.image {
    float: left;

    width: 15%;
    padding: 0 10px 0 0;
}
.dccom_gdtestimonials div.image img {
    width: 100%;
}
.dccom_gdtestimonials blockquote {
    position: relative;

    float: left;

    width: 80%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    border: none;
    background: transparent !important;
    background-position: 20px 0 !important;
}

.dccom_gdtestimonials blockquote:after {
    font: normal normal normal 20px/1 FontAwesome;

    position: absolute;
    top: 0;
    left: 24px;

    content: "\f10d";
}
.dccom_gdtestimonials blockquote:before {
    font: normal normal normal 20px/1 FontAwesome;

    position: absolute;
    right: 0;
    bottom: 0;

    content: "\f10e";
}

blockquote {
    border-color: #f2c3ca;
}
.dccom_gdtestimonials blockquote i {
    display: none;
}

.bigQuoteSlider li {
    clear: both;
    overflow: hidden;

    margin-bottom: 20px;
}
.dccom_gdtestimonials .bigQuoteSlider {
    width: 100%;
    margin-left: 0;
}
.dccom_gdtestimonials .bigQuote {
    padding-left: 0;
}

form.formWizForm label {
    color: #333333 !important;
}
form.formWizForm .field {
    position: relative;
}
form.formWizForm input[type="text"],
form.formWizForm textarea {
    width: 100% !important;
    padding: 3px 7px;

    border: 1px solid #cccccc;
}
div.left.emailIcon {
    display: none;
}
form.formWizForm label.error {
    font-size: 11px;

    color: #f2c3ca !important;
}
.submitBut {
    font-weight: 600;
    line-height: 19px;

    padding: 8px 30px;

    border: 2px solid #f2c3ca;
}
.submitBut:hover {
    color: #f2c3ca;
    background-color: transparent;
}
#GDFormLI1de003b9ddd50001e8a616871ef01c6c,
#GDFormLI1de003b9e4480001ecd0d44017a111b7 {
    float: left;
    clear: none;

    width: 50%;
}
#GDFormLI1de003b9ddd50001e8a616871ef01c6c .field {
    margin-right: 15px;
}
.contact-address-field {
    margin-bottom: 15px;
    /*float: left;
     width: 33.333%;
     padding: 0 10px;*/

    border-bottom: 1px solid #cccccc;
}
.contact-address-field p {
    margin-top: 0;
}
.contact-address-field h2 {
    font-size: 21px;

    margin-bottom: 5px;
}
#gdmap_canvas {
    clear: both;

    width: 100% !important;
    margin-top: 15px;
}
#mapText {
    margin-top: 15px;
    padding-left: 0 !important;
}
form#drivingForm input.text {
    width: 100% !important;
}
form#drivingForm input.btn {
    font-size: 18px !important;
    font-weight: 600;
    line-height: 19px !important;

    width: 100% !important;
    height: 40px !important;
    padding: 8px 30px !important;

    border: 2px solid #f2c3ca !important;
    background-color: #f2c3ca !important;
}
form#drivingForm input.btn:hover {
    color: #f2c3ca !important;
    background-color: transparent !important;
}

body#blog .dccom {
    width: auto;
    max-width: 100%;
    margin: 0 -10px;
}
.postTags a,
.content .blogSlider .slick-slide::before {
    background-color: #f2c3ca !important;
}
body .content .blogList .inner {
    position: relative;

    padding-bottom: 70px;

    border: 1px solid #cccccc;
    border-radius: 0;
}
body .content .blogList h3 a {
    font-size: 23px;

    color: inherit;
}
.blogList p.more a {
    font-size: 146x;

    position: absolute;
    bottom: 15px;
    left: 15px;

    display: inline-block;

    padding-top: 5px;
    padding-bottom: 5px;
}
body .content .blogList .date {
    font-style: italic;

    text-transform: none;

    opacity: 0.7;
    color: #6a7f96;
}
#addComment,
#addComment-reply {
    font-weight: 600;
    line-height: 19px;

    display: inline-block;

    padding: 8px 30px;

    color: #ffffff;
    border: 2px solid #f2c3ca;
    background-color: #f2c3ca;
}
#addComment-reply {
    margin-top: 10px;

    border-radius: 60px;
}
#addComment:hover,
#addComment-reply:hover {
    text-decoration: none;

    color: #f2c3ca;
    background-color: #ffffff;
}

#messageContainer {
    position: relative;

    padding-left: 30px;

    background-color: #ffced5;
}
#messageContainer > i {
    position: absolute;
    top: 30px;
    left: 15px;

    color: #f2c3ca;
}
#messageContainerClose {
    color: #f2c3ca;
}
#messageContainer > div {
    padding: 0 10px;
}
#messageContainerClose {
    position: absolute;
    top: 25px;
    right: 10px;

    opacity: 1;
    color: #f2c3ca;
}
.content ul.blogPagination .sel a {
    background-color: #f2c3ca !important;
}

.blog-post-Comments {
    margin: 0 0 20px !important;
}
.opening > span {
    text-transform: uppercase;
}

/*@media
 ****************/

@media all and (min-width: 1200px) {
    /* footer sticky***/
    html {
        position: relative;

        min-height: 100%;
    }
    html,
    body {
        height: 100%;
        margin: 0;
    }
    /*body:not(#home) {
         margin-bottom: 384px;
     }*/
    body:not(#home) #wrap {
        position: relative;

        margin-bottom: 384px;
    }

    // body:not(#home) .footer {
    // position: absolute;
    // top: 100%;

    // width: 100%;
    // height: 384px;
    // }
}

@media all and (max-width: 1600px) {
    .banner .map-location {
        height: 530px;
    }
    .banner .image-location .text {
        top: 21%;
        left: 21%;

        width: 60%;
    }
    .infoBlock .image {
        top: -60px;
        left: -80px;
    }
}

@media all and (max-width: 1400px) {
    .banner .image-location .text {
        left: 18%;

        width: 70%;
    }
    .banner .image-location .btn {
        font-size: 16px;

        padding: 8px 15px;
    }

    .infoBlock a {
        padding: 15px 0;
    }
    .infoBlock .image {
        position: absolute;
        top: -35px;
        left: -25px;
    }
    .testimonialBlock .image {
        right: -40px;

        margin-top: -95px;
    }
}
@media all and (max-width: 1260px) {
    .infoBlock .image {
        left: -10px;
    }
}

@media all and (max-width: 1200px) {
    .mainNav > li > a {
        font-size: 17px;
        font-weight: bold !important;

        margin-right: 3px;
    }
    .mainNav .sub-menu {
        width: 330px;
    }
    .banner .text {
        padding: 20px;
    }
    .banner .image-location .text {
        width: 77%;
    }
    .banner .map-location,
    .banner .image,
    .banner .image-location {
        height: 330px;
    }
    .btn {
        font-size: 17px;

        padding: 10px;
    }
    .copy .service-list li a {
        font-size: 16px;
    }
    .banner .image-location .btn {
        padding: 7px 10px;
    }
    .infoBlock .text {
        padding-left: 15px;
    }
}

/*.mainNav.mobile {
     display: none!important;
 }
 @media all and (max-width: 992px) {
     .mainNav.mobile{
         display: block!important;
     }
 }*/

@media all and (max-width: 1023px) {
    div#stickycookiePolicy a.close {
        float: none;
    }
}
@media all and (max-width: 1023px) {
    .testimonialBlock .text p {
        margin-bottom: 5px;
    }
    .testimonialBlock .text {
        margin-top: 45px;

        background-size: 14%;
    }
    .testimonial-slider .slick-prev,
    .testimonial-slider .slick-next,
    .testimonial-slider .slick-prev:before,
    .testimonial-slider .slick-next:before {
        width: 45px;
        height: 45px;
    }
    .testimonial-slider .slick-next {
        left: 125px;
    }
}

@media all and (max-width: 991px) {
    .pageNavList {
        margin-bottom: 20px;
    }
    .header,
    body#home .header {
        position: relative;
    }
    .header .contact-btn {
        margin-bottom: 10px;
    }
    .slick-prev::before,
    .slick-next::before {
        font-size: 73px;
    }
    .slick-prev {
        top: 35%;
        left: 10px;
    }
    .slick-next {
        top: 40%;
        right: 10px;
    }
    .btn {
        font-size: 16px;

        padding: 5px 10px;
    }
    .infoBlock {
        margin: 60px 0 30px;
    }
    .infoBlock .image {
        position: relative;
        top: 0;
        left: 0;

        margin-top: 15px;
    }
    // #home .contentWrap {
    //     margin-top: 0;
    // }
    .testimonial-slider {
        width: 95%;
        margin: 0 auto;
        padding-bottom: 50px;
    }
    .testimonial-slider .slick-prev,
    .testimonial-slider .slick-next {
        bottom: 0;
    }
    .testimonial-slider .slick-next {
        right: 65px;
        left: auto;
    }
    .testimonialBlock .text {
        float: none;

        min-height: auto;
        margin: 0 auto;
        padding: 20px;
    }
    .image-testimonial {
        position: relative;

        width: 55%;
        margin: 15px auto;
    }
    .text-testimonial {
        width: 100%;
        margin-top: 50px;
    }
    .breadcrumb {
        margin-left: 5px;
    }
    .footer .span5 {
        width: 33.3333%;
        min-height: 285px;
    }
    .footer .span5.last {
        width: 50%;
    }
    .dccom_gdtestimonials div.image {
        width: 25%;
    }
    .dccom_gdtestimonials blockquote {
        width: 75%;
    }
    div#mapText {
        width: 355px !important;
    }
}

@media all and (max-width: 767px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 10px;
    }
    .h1,
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    body#home .contentWrap h1 {
        font-size: 24px;
    }
    body {
        font-size: 16px;
    }
    .header .btn {
        font-size: 18px;

        padding: 9px 13px;
    }
    .header .contact-btn .btn span {
        font-size: 18px;
    }
    .banner .text,
    .banner .image-location .text {
        right: 0;
        left: 0;

        width: 100%;
        margin: 0 auto;
    }
    .banner .map-location {
        /*	width: 100%;
         float: none;
         height: 270px;*/
        display: none;
    }
    .banner .image-location {
        float: none;

        width: 100%;
    }

    .banner .image img {
        height: 280px;
    }
    .servicesWrap .col-sm-6 {
        float: left;

        width: 50%;
    }
    .infoBlock .text {
        padding: 0 30px;
    }
    .infoBlock a p {
        margin-bottom: 8px;
    }
    .infoBlock .image {
        padding: 0 20px;
    }
    .testimonialBlock {
        margin: 30px 0;

        background: #f2f2f2 url("../images/layout/marker-testimonial.png")
            no-repeat scroll 20px 20px;
        background-size: 15%;
    }
    .testimonialBlock .text {
        margin-top: 20px;
        padding-top: 20px;
        padding-left: 15px;

        background: none;
    }
    .testimonial-slider .slick-prev,
    .testimonial-slider .slick-next {
        bottom: 20px;
    }
    .testimonialBlock .text p {
        margin-bottom: 8px;
    }
    .testimonialBlock .image-small {
        margin-bottom: 20px;
        padding: 0 50px;
    }
    .testimonial-slider {
        padding-bottom: 70px;
    }
    .pageNavList {
        margin-bottom: 25px;
    }
    .pageNavList > li > a {
        font-size: 16px;

        padding: 8px 20px;
    }
    .intro,
    .copy > p:first-child {
        padding: 20px;
    }
    .panel .panel-body {
        padding: 15px;
    }

    .footer {
        font-size: 14px;

        padding: 25px 0;
    }
    .footer .span5 {
        width: 50%;
        min-height: 265px;
    }
    .footer .span5.last {
        width: 100%;
    }
    .footer h2 {
        font-size: 16px;
    }
    .contact-address-field {
        float: none;

        width: 100%;
        padding: 0 10px;
    }
    .breadcrumb > li + li::before {
        padding-right: 5px;
    }
    .blogPostWrap h3 {
        padding: 5px 10px !important;
    }
    .dccom_gdtestimonials blockquote:after {
        left: 3px;
    }
}
@media all and (max-width: 680px) {
    .header .btn {
        padding: 6px 15px;
    }
    .copy .service-list li {
        padding: 0 7px;
    }
    .copy .service-list li a {
        font-size: 14px;
    }
    .copy .service-list li a p {
        padding: 0 3px;
    }
    .separator {
        font-size: 13px;

        display: block;
    }
    .testimonial-slider {
        width: 95%;
    }
}
@media all and (max-width: 640px) {
    .dccom_gdtestimonials div.image,
    .dccom_gdtestimonials blockquote {
        float: none;

        width: 100%;
    }
    .dccom_gdtestimonials div.image img {
        display: block;

        width: 70%;
        margin: 0 auto 15px;
    }
    .dccom_gdtestimonials blockquote {
        margin-bottom: 5px;
        padding: 0 5px 0 30px !important;

        background-position: 0 0 !important;
        background-size: 5% auto !important;
    }
    div#stickycookiePolicy a.close {
        float: none;

        width: 30px;
    }
    div#stickycookiePolicy a.close:before {
        font-size: 23px;

        top: 4px;
        left: 9px;
    }
    .news-item .featured-image {
        width: 100%;
    }
    .panel-body img {
        width: 100%;
        height: auto;
    }
}

@media all and (max-width: 480px) {
    body#home .contentWrap h1 {
        font-size: 20px;
    }

    #gdmap_canvas,
    #gdmap_canvas img,
    div#mapText {
        width: 100% !important;
    }
    .banner .image img {
        height: 200px;
    }
    .banner .image-location .btn {
        margin-top: 0;
    }
    .banner .image-location .text {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .homeBanner .image {
        position: relative;

        width: 100%;
        min-height: 330px;
    }
    .homeBanner .image .mobile {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .homeBanner .image .desktop {
        display: none;
    }

    .footer .span5 {
        width: 100%;
        min-height: auto;
        margin-bottom: 15px;

        text-align: center;
    }

    .footer #footernav-block,
    .footer #footerother-block {
        display: none;
    }
}

.gm-style-iw {
    color: #000000 !important;
}

.header.new {
    min-height: 100px;
    .container {
        position: relative;

        display: flex;
        // align-items: center;
        justify-content: space-between;

        // height: 100%;
    }
    .menu-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        width: 100%;
        .mainNav {
            margin-top: 0;
            .home {
                @media all and (min-width: 992px) {
                    display: none;
                }
            }
            @media screen and (min-width: 992px) {
                display: flex;
            }

            > li a {
                font-size: 16px;
                font-weight: normal;

                color: #3d4f5b;
            }
            > li:not(:last-child) {
                @media screen and (min-width: 992px) {
                    margin-right: 18px;
                }
                @media screen and (min-width: 1200px) {
                    margin-right: 24px;
                }
                @media screen and (min-width: 1440px) {
                    margin-right: 40px;
                }
            }
            > li:last-child {
                @media all and (min-width: 992px) {
                    margin-left: 10px;
                }
            }
            //     a {
            //         @media all and (min-width: 992px) {
            //             font-weight: 600;

            //             padding: 10px 12px;

            //             text-transform: uppercase;

            //             color: white;
            //             border-radius: 6px;
            //             background-color: #35505a;
            //         }
            //     }
        }
    }
}
.call-us {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -30px;
    left: 0;

    @media screen and (max-width: 991px) {
        text-align: center;
    }
    @media screen and (min-width: 992px) {
        top: -10px;
        right: 15px;
        bottom: auto;
        left: auto;
    }
    .fa {
        margin-right: 6px;

        color: #35505a;
    }
    .call-us__button {
        color: #56313b;
        span {
            font-weight: 600;
            @media (min-width: 992px) {
                font-size: 20px;
            }
        }
    }
}
.btn {
    font-size: 12px;
    font-weight: 600;

    padding: 8px 16px;

    text-transform: uppercase;

    border-radius: 6px;
    @media screen and (min-width: 768px) {
        font-size: 14px;

        padding: 12px 24px;
    }
    @media screen and (min-width: 992px) {
        font-size: 16px;

        padding: 17px 50px;
    }
}
.btn--blue-soft {
    color: black;
    background-color: #b3cfd9;
}
.btn--pink {
    color: black;
    background-color: #f2c3ca;
    &:hover,
    &:focus {
        color: white;
    }
}
.service-list,
.servicesWrap {
    .row + .row {
        margin-top: 15px;
        @media screen and (min-width: 992px) {
            margin-top: 30px;
        }
    }
    .btn {
        margin-top: 30px;
    }

    a p {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.1;

        color: #333333;
        @media (min-width: 992px) {
            font-size: 20px;
        }
    }
}
.btn {
    &:hover {
        background-color: #35505a;
        color: white;
    }
}
.gallery-slider {
    .slick-slide {
        margin-right: 20px;
    }
}
.section--gallery {
    a {
        display: block;

        margin-bottom: 20px;

        text-decoration: underline;

        color: #56313b;
    }
}
.gallery-slider-controls {
    display: flex;
}
.gallery-slider__prev,
.gallery-slider__next {
    cursor: pointer;
}
.gallery-slider__next {
    margin-left: 25px;
}
.section {
    margin-top: 70px;
    margin-bottom: 80px;
}
.section--inner {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 98px;
    padding-bottom: 33px;

    background-color: #f2c3ca;
    h1 {
        text-transform: none;

        color: #56313b;
    }
}
.section--default {
    .btn {
        margin-top: 30px;
    }
}
.section--bleed-right {
    @media (min-width: 768px) {
        .container {
            width: calc(((100% - 750px) / 2) + 750px) !important;
            margin-right: 0;
        }
    }

    @media (min-width: 992px) {
        .container {
            width: calc(((100% - 970px) / 2) + 970px) !important;
            margin-right: 0;
        }
    }

    @media (min-width: 1200px) {
        .container {
            width: calc(((100% - 1170px) / 2) + 1170px) !important;
            margin-right: 0;
        }
    }

    @media (min-width: 1600px) {
        .container {
            width: calc(((100% - 1370px) / 2) + 1370px) !important;
            margin-right: 0;
        }
    }
}
.section--bleed-left {
    @media (min-width: 768px) {
        .container {
            width: calc(((100% - 750px) / 2) + 750px) !important;
            margin-left: 0;
        }
        .image {
            overflow: hidden;
        }
        // img {
        //     height:100%;
        //     object-fit: cover;
        // }
    }

    @media (min-width: 992px) {
        .container {
            width: calc(((100% - 970px) / 2) + 970px) !important;
            margin-left: 0;
        }
    }

    @media (min-width: 1200px) {
        .container {
            width: calc(((100% - 1170px) / 2) + 1170px) !important;
            margin-left: 0;
        }
    }

    @media (min-width: 1600px) {
        .container {
            width: calc(((100% - 1370px) / 2) + 1370px) !important;
            margin-left: 0;
        }
    }
}
#services {
    .sidebar {
        display: none;
    }
    .content.col-md-8 {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
}
.section--testimonials {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 80px;

    background-color: #f2f2f2;
    .container {
        position: relative;
    }
}
.testimonials-slider-controls {
    position: absolute;
    top: 50%;
    left: 0;

    display: flex;
    justify-content: space-between;

    width: 100%;

    transform: translateY(-50%);
    .testimonials-slider__next,
    .testimonials-slider__prev {
        cursor: pointer;
    }
}
.testimonials-slider__slide {
    text-align: center;
    img {
        margin-right: auto;
        margin-bottom: 30px;
        margin-left: auto;
    }

    .testimonials-slider__quote {
        line-height: 175%;

        max-width: 760px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        padding-bottom: 20px;
        @media (min-width: 768px) {
            font-size: 20px;
        }
        p {
            display: inline;
        }
        &::before {
            margin-right: 10px;

            background-image: url("../images/layout/icon-quote.png");
            content: "";
            background-size: cover;
            height: 33.8px;
            width: 40px;
            display: inline-block;
        }
        &::after {
            margin-left: 10px;
            top: 20px;
            position: relative;

            background-image: url("../images/layout/icon-quote-e.png");
            content: "";
            background-size: cover;
            height: 33.8px;
            width: 40px;
            display: inline-block;
        }
    }
}
.mainNav .haschildren {
    position: relative;
}
.mainNav .haschildren::before {
    @media all and (min-width: 992px) {
        position: absolute;
        top: 50%;
        right: -11px;

        width: 8px;
        height: 4px;

        content: "";
        transform: translateY(-50%);

        background-image: url("../images/layout/arrow.png");
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.footer__extra {
    margin-top: 20px;
    padding-top: 20px;

    border-top: 1px solid #f2c3ca;
    @media (min-width: 1200px) {
        margin-top: 60px;
        padding-top: 60px;
    }
    ul {
        @media (min-width: 768px) {
            display: flex;
            li:not(:last-child) {
                margin-right: 20px;
                &::after {
                    margin-left: 20px;

                    content: " | ";
                }
            }
        }
    }
}
div.address {
    width: auto !important;
}
.section {
    #dccom_gdImageGallery {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        @media all and (min-width: 576px) {
            justify-content: space-between;
        }
        .gdImage {
            border: 0;
            float: none;
            margin: 0;
            padding: 0;
            min-width: 0 !important;
            @media all and (min-width: 576px) {
                width: calc(50% - 5px) !important;
            }
            @media all and (min-width: 992px) {
                width: calc(33.3334% - 5px) !important;
            }
            @media all and (min-width: 1200px) {
                width: calc(33.3334% - 10px) !important;
            }
            a {
                display: flex;
                width: auto;
            }
            img {
                width: 100%;
                border: 0;
                padding: 0;
                margin: 0;
                object-fit: cover;
                @media all and (min-width: 992px) {
                    min-height: 250px;
                }
                &:hover {
                    margin: 0;
                    padding: 0;
                    border: 0;
                }
            }
            .title {
                font-size: 16px;
                font-weight: 300;
                margin-top: 10px;
                color: #35505a;
            }
        }
    }
}
@media all and (min-width: 768px) {
    .section--bleed-left,
    .section--bleed-right {
        .row {
            display: flex;
            align-items: center;
            .image {
                img {
                    width:100%;
                }
            }
            .col-sm-6 {
                float:none;
            }
        }
    }
    .section + .banner {
        h1 {
            font-size: 30px !important;
        }
        p {
            font-size: 16px;
            line-height: 23px;
        }
        .btn {
            font-size: 14px;
            line-height: 1;
            padding: 12px 16px;

        }
    }
}
.panel {
    background-color: transparent;
}
.panel.panel-default .panel-body {
    background-color: white;
}
.section--gray {
    background:#F2F2F2;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 80px;
}
.sidebar .onPath.sel {
    > a {
        padding:10px;
        background-color: rgba(#F2C3CA, 50%);
        color: #3D4F5B;
        border-radius: 6px;

    }
}